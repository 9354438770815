import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AlertProvider } from './alert-context';
import theme from '../styles/theme';

const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <AlertProvider>{children}</AlertProvider>
    </ThemeProvider>
  );
};

export default Providers;
