import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const AlertStateContext = React.createContext();
const AlertDispatchContext = React.createContext();

function countReducer(state, action) {
  switch (action.type) {
    case 'load': {
      return [...action.payload];
    }
    case 'close': {
      return [...state.filter(alert => alert.id !== action.payload)];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const query = graphql`
  query LayoutQuery {
    allContentfulAlert(
      filter: { isActive: { eq: true } }
      sort: { fields: sortOrder, order: ASC }
    ) {
      edges {
        node {
          id
          text {
            text
          }
          ctaText
          ctaLink
          color
        }
      }
    }
  }
`;

function AlertProvider({ children }) {
  const data = useStaticQuery(query);

  const alerts = data.allContentfulAlert.edges.map(({ node }) => node);
  const [state, dispatch] = React.useReducer(countReducer, alerts);

  return (
    <AlertStateContext.Provider value={state}>
      <AlertDispatchContext.Provider value={dispatch}>
        {children}
      </AlertDispatchContext.Provider>
    </AlertStateContext.Provider>
  );
}

function useAlertState() {
  const state = React.useContext(AlertStateContext);
  if (state === undefined) {
    throw new Error('useAlertState must be used within a AlertProvider');
  }
  return state;
}

function useAlertDispatch() {
  const dispatch = React.useContext(AlertDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useAlertDispatch must be used within a AlertProvider');
  }
  const closeAlert = id => dispatch({ type: 'close', payload: id });
  return { closeAlert };
}

export { AlertProvider, useAlertState, useAlertDispatch };
