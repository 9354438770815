exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

