const theme = {
  colors: {
    primary: '#A04A44',
    secondary: '#536C85',
    grayDark: '#363131',
    grayMedium: '#6A6565',
    grayLight: '#9F9C9C',
    grayLighter: '#D9D7D7',
    grayLightest: '#F3F3F3',
    gold: '#a68564',
    goldDark: '#8a6f53',
  },
  fonts: {
    primary: `'Source Sans Pro', Arial, Helvetica, sans-serif`,
    secondary: `'Oswald', Arial, Helvetica, sans-serif`,
    handwriting: `Rock Salt, Georgia, serif`,
  },
  breakpoints: {
    mobile: '43rem',
  },
};

export default theme;
